import WinningPoolProgress from '../progress/WinningPoolProgress';
import './Card.css';

function FavorCard(props) {
  const { borderColor, poolData } = props;

  return (
    <div className="Card" style={{ '--border-color': borderColor }}>
        <div className='Card-title'>
            <h1 className='Card-title-text'>FAVOR Meter</h1>
        </div>
        <>
            {/* Pool Favor ring  */}
            <div className='favor-meter'>
                <div className='favor-meter-ring'>
                    <WinningPoolProgress poolData={poolData} />
                </div>
            </div>

            {/* Pool Favor Info */}
            <div className='favor-info'>
              <div className='favor-info-container'>
                <p className='favor-stake-info-label'>Pool 1 FAVOR</p>
                <p className='user-stake-info-text'>{poolData && poolData.pool1Favor} FAVOR</p>
              </div>
              <div className='stake-info-container'>
                <p className='favor-stake-info-label'>Pool 2 FAVOR</p>
                <p className='user-stake-info-text'>{poolData && poolData.pool2Favor} FAVOR</p>
              </div>
            </div>
        </>
    </div>
  );
}

export default FavorCard;
