import React, { useEffect, useState } from 'react';
import './Card.css';
import favorLogo from '../../assets/images/Candy.png';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { Formik } from 'formik';
import { useAccount, isConnected } from 'wagmi'
import { getFavorPrice, buyFavor, getFavorPriceRPC } from '../../web3/web3';
import { useTxnPending } from '../../contexts/TxnPendingContext';

function BuyCard(props) {
    const { poolColor, balances } = props
    const { connector, isConnected } = useAccount();
    const [price, setPrice] = useState();
    const [signer, setSigner] = useState();
    const { txnPending, setTxnPending } = useTxnPending();

    useEffect(() => {
        async function initialize() {
            const _signer = await connector.getSigner();
            console.log('signer', _signer)
            setPrice(await getFavorPrice(_signer, 1));
            console.log('price', price);
            setSigner(_signer);
        }

        async function notConnectInit(){
            setPrice(await getFavorPriceRPC(1));
        }

        // console.log('signer: ', signer)
        if(connector) {
        console.log('connector', connector);
        initialize();
        }else{
            notConnectInit();
        }
    }, [connector, setPrice])


    return (
        <Formik
            initialValues={{ amount: 1 }}
            onSubmit={(values, { setSubmitting }) => {
                console.log('values', values);
                buyFavor(signer, values.amount, setTxnPending)
            }}
            onChange={(values) => async () => {
                setPrice(await getFavorPrice(signer, values));
            }}
  

        >
            {/* Input */}
            {({ handleSubmit, isSubmitting, values, handleChange, setFieldValue }) => {
                const increment = async () => {
                    setPrice(await getFavorPrice(signer, values.amount + 1));
                    setFieldValue("amount", values.amount + 1);
                };

                const decrement = async () => {
                    if (values.amount > 0) {
                        setPrice(await getFavorPrice(signer, values.amount - 1));
                        setFieldValue("amount", values.amount - 1);
                    }
                };
                return (
                    <div className="Card" style={{ '--border-color': poolColor }}>
                        <div className="Buy-Box">
                          <div className="Candy-Box">
                            <div className="Buy-Box-Text-container">
                                <div className="Buy-Box-Title-Div">
                                <h4 className="Buy-Box-Title">
                                    Buy FAVOR
                                </h4>
                                </div>
                                <div className="Buy-Box-Text-Div">
                                <p className="Buy-Box-Text">
                                    Contribute $FAVOR to a pool to increase its chances of winning
                                </p>
                                </div>
                            </div>
                            </div>
                        </div>
                   {isConnected ? <>
                        <div className="Buy-Box-Input-container">
     
                            <div className="Buy-Box-Input-Wrapper">
                                <p className="Favor-Input-Label-Text">Quantity of FAVOR:</p>
                                <div className="Favor-Input-Container">
                                    <button
                                        disabled={values.amount === 1}
                                        className="Favor-Input-Button"
                                        onClick={decrement}
                                    >
                                        <FaMinus />
                                    </button>
                                    <input
                                        disabled
                                        type="number"
                                        min="0"
                                        className="Buy-Box-Input Favor-Input"
                                        name="amount"
                                        value={values.amount}
                                    />
                                    <button className="Favor-Input-Button" onClick={increment}>
                                        <FaPlus />
                                    </button>
                                </div>
                            </div>
                            

                            <div className="Total-Input-Wrapper">
                                <p className="Favor-Input-Label-Text">Total:</p>
                                <div className="total-container">
                                    <input value={price && Number(price).toLocaleString()} type="text" className="Buy-Box-Input" />
                                </div>
                            </div>
                        </div>
                        <button onClick={handleSubmit} className='Purchase-Button' type="submit" disabled={txnPending} >
                            Purchase
                        </button>
        

                        <div className='stake-info'>
                            <div className='stake-info-container'>
                                <p className='stake-info-label'>Price per Favor</p>
                                <p className='stake-info-value'>{price && (Number(price) / values.amount).toLocaleString()} PARTY</p>
                            </div>
                            <div className='stake-info-container'>
                                <p className='stake-info-label'>PARTY Balance</p>
                                <p className='stake-info-value'>{balances && Number(Number(balances.partyToken).toFixed(2)).toLocaleString()} PARTY</p>
                            </div>
                            <div className='stake-info-container'>
                                <p className='stake-info-label'>FAVOR Balance</p>
                                <p className='stake-info-value'>{balances && balances.favor}</p>
                            </div>

                        </div>
                        </> :
                          <div className='stake-info'>
                            <div className='stake-info-container'>
                                <p className='stake-info-label'>Price per Favor</p>
                                <p className='stake-info-value'>{price && (Number(price) / values.amount).toLocaleString()} PARTY</p>
                            </div>
                            <div className='stake-info-container'>
                                <p className='stake-info-label'></p>
                                <p className='stake-info-value'></p>
                            </div>
                            <div className='stake-info-container'>
                                <p className='stake-info-label'></p>
                                <p className='stake-info-value'></p>
                            </div>
                             <button className='Purchase-Button' type="button" style={{background: 'grey', cursor: 'not-allowed'}} >
                            Not Connected
                        </button>
                        </div>
                        }

                    </div >
                );
            }}
        </Formik>
    )
}

export default BuyCard