import React, { useState, useEffect } from 'react';
import './StakesCard.css';
import { contributeFavor, jump, unstake, unstakePool3, claimPool3 } from '../../web3/web3';
import { useAccount } from 'wagmi'
import { useTxnPending } from '../../contexts/TxnPendingContext';
import { usePoolsClosed } from '../../contexts/PoolsClosedContext';

function getTimeRemaining(endTime) {
    const now = new Date();
    const remainingMilliseconds = endTime - now;
  
    const remainingSeconds = Math.floor(remainingMilliseconds / 1000);
    const days = Math.floor(remainingSeconds / 86400);
    const hours = Math.floor((remainingSeconds % 86400) / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);
    const seconds = remainingSeconds % 60;
  
    return { days, hours, minutes, seconds };
  }

  

function formatTime(timeRemaining) {
    const { days, hours, minutes, seconds } = timeRemaining;
    return `${days}d ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  

const fakeLockTime = new Date(Date.now() + 86400000);
const timeRemaining = getTimeRemaining(fakeLockTime);
const formattedTime = formatTime(timeRemaining);

console.log(formattedTime);
  

const fakeData = { 
        isLocked: true,
        lockedTime: formattedTime,
        poolSize: 1000,
        poolAPY: 3200,
        poolEarnings: 36420,
        poolStake: 3235,
}




function StakesCard(props) {
    const { poolColor, balances, poolData } = props;
    const { connector } = useAccount();
    const [signer, setSigner] = useState();
    const { txnPending, setTxnPending } = useTxnPending();
    const { poolsClosed, setPoolsClosed } = usePoolsClosed();

    useEffect(() => {
        async function initialize() {
            const _signer = await connector.getSigner();
            setSigner(_signer);
        }

        if(connector) {
            console.log('connector', connector);
            initialize();
        }
    }, [connector])

    // Perhaps reduce code by sending pool data as props to this component

    return (
        <div className="Card" style={{ '--border-color': poolColor }}>
            <div className='Card-title'>
                <h1 className='Card-title-text'>My Stakes</h1>
            </div>
            <div className='stakes-section'>
                {/*  Pool A */}
                <div className='stakes-card-item'>
                    <div className='stakes-card-item-content'>
                        {/*  Name of pool */}
                        <div className='stakes-info' >
                            <p className='stakes-text'>Pool 1</p>
                        </div>
                        {/* User Stake amount in pool */}
                        <div className='stakes-info'>
                            <p className='stakes-text'>
                                {balances && Number(Number(balances.pool1).toFixed(2)).toLocaleString()} PARTY
                            </p>
                            <p className='stakes-text-faded'>Staked</p>
                        </div>
                        {/* User APY % in pool */}
                        <div className='stakes-info'>
                            <p className='stakes-text-apy'>
                                {poolData && Number((poolData.pool1Winnings * 365).toFixed(2)).toLocaleString()}%
                            </p>
                            <p className='stakes-text-faded'>Win APR</p>
                        </div>
                        {/* Locked or unlocked */}
                        {/* <div className='stakes-info'>
                            {fakeData.isLocked ? (
                                <>
                                    <p className='stakes-text'>Locked</p>
                                    <p className='stakes-text-faded'>{fakeData.lockedTime}</p>
                                </>
                            ) : (
                                <>
                                    <p className='stakes-text'>Unlocked</p>
                                    <p className='stakes-text-faded'>Unlocked</p>
                                </>
                            )}
                        </div> */}
                    </div>
                    {/* Buttons */}
                    <div className='button-container'>
                        {!poolsClosed &&
                            <button onClick={() => {
                                unstake(signer, balances.pool1, 0, setTxnPending)
                            }} disabled={txnPending} className='stakes-unstake-button'>Unstake All</button>                        
                        }
                        <button onClick={() => {
                            contributeFavor(signer, 1, 0, setTxnPending)
                        }} disabled={txnPending} className='stakes-contribute-button'>Contribute FAVOR</button>
                        <button onClick={() => {
                            jump(signer, 1, setTxnPending)
                        }} disabled={txnPending} className='stakes-jump-button'>Jump Here</button>
                    </div>
                </div>

                {/* Pool B */}
                <div className='stakes-card-item'>
                    <div className='stakes-card-item-content'>
                        {/*  Name of pool */}
                        <div className='stakes-info' >
                            <p className='stakes-text'>Pool 2</p>
                        </div>
                        {/* User Stake amount in pool */}
                        <div className='stakes-info'>
                            <p className='stakes-text'>
                                {balances && Number(Number(balances.pool2).toFixed(2)).toLocaleString()} PARTY
                            </p>
                            <p className='stakes-text-faded'>Staked</p>
                        </div>
                        {/* User APY % in pool */}
                        <div className='stakes-info'>
                            <p className='stakes-text-apy'>
                                {poolData && Number((poolData.pool2Winnings * 365).toFixed(2)).toLocaleString()}%
                            </p>
                            <p className='stakes-text-faded'>Win APR</p>
                        </div>
                        {/* Locked or unlocked */}
                        {/* <div className='stakes-info'>
                            {fakeData.isLocked ? (
                                <>
                                    <p className='stakes-text'>Locked</p>
                                    <p className='stakes-text-faded'>{fakeData.lockedTime}</p>
                                </>
                            ) : (
                                <>
                                    <p className='stakes-text'>Unlocked</p>
                                    <p className='stakes-text-faded'>Unlocked</p>
                                </>
                            )}
                        </div> */}
                    </div>
                    {/* Buttons */}
                    <div className='button-container'>
                        {!poolsClosed &&
                            <button onClick={() => {
                                unstake(signer, balances.pool2, 1, setTxnPending)
                            }} disabled={txnPending} className='stakes-unstake-button'>Unstake All</button>
                        }
                        <button onClick={() => {
                            contributeFavor(signer, 1, 1, setTxnPending)
                        }} disabled={txnPending} className='stakes-contribute-button'>Contribute FAVOR</button>
                        <button onClick={() => {
                            jump(signer, 0, setTxnPending)
                        }} disabled={txnPending} className='stakes-jump-button'>Jump Here</button>
                    </div>
                </div>


              {balances && balances.pool3 > 0 &&   
              <div className='stakes-card-item'>
                    <div className='stakes-card-item-content'>
                        <div className='stakes-info' >
                            <p className='stakes-text'>Pool 3</p>
                        </div>
                        <div className='stakes-info'>
                            <p className='stakes-text'>
                                {balances && balances.pool3} LP
                            </p>
                            <p className='stakes-text-faded'>Staked</p>
                        </div>
                        <div className='stakes-info'>
                            <p className='stakes-text-apy'>
                                {poolData && Number(poolData.pool3APY).toLocaleString()}%
                            </p>
                            <p className='stakes-text-faded'>APR</p>
                        </div>
                        <div className='stakes-info'>
                            <p className='stakes-text'>
                                {balances && balances.pool3Earnings} Party
                            </p>
                            <p className='stakes-text-faded'>Earnings</p>
                        </div>
                    </div>
                    <div className='button-container'>
                        <button onClick={() => {
                            unstakePool3(signer, balances.pool3, setTxnPending)
                        }} disabled={txnPending} className='stakes-unstake-button'>Unstake All</button>  
                        {balances && balances.pool3TargetTime < new Date() && Number(balances.pool3Earnings) > 0 && 
                            <button onClick={() => {
                                claimPool3(signer, setTxnPending)
                            }} disabled={txnPending} className='stakes-jump-button'>Claim Rewards</button>                                              
                        }
                     </div>       

                </div>}
                
            </div>
        </div>
    )
}

export default StakesCard;