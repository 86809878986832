import PartyToken from "../abis/PartyToken.json";
import Favor from "../abis/Favor.json";
import sParty from "../abis/sParty.json";
import PoolParty from "../abis/PoolParty.json";
import Pool3 from "../abis/Pool3.json";
import { Contract, Provider } from 'ethers-multicall';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

const partyTokenAddress = "0x0fE0Ed7F146Cb12e4B9759afF4FA8d34571802ca" //"0x8bb63Eb069f701f1F24e7b168F6D767b1b5882f4";
const favorAddress = "0x2f9C2A3BAeee56d0957539a6BbF96DD303697084" //"0x348B6A9c973eB6a4A2c52568bD0f47676F1f4b7d"
const pool1Address = "0xc01dcef6c78A4978F3411d518Be9f36F2bE1444f" //"0x06d2Bc2f44d00c528Ec6e86D5F15EDEE742F5a0c"; 
const pool2Address = "0x1A735f7dDbAE8641D364eE873F01eb09A60bB2E7" //"0xf6CC2DFca4da5f614467dB9A4bdDC3c80AA2B05b"
const poolPartyAddress = "0xfDEB03709453225D22E5a51A7f93bd0E97984D8C" //"0xAf498825219fAAFE4599Eb3cc71fB20fc20E510A";
const pool3Address = "0xD098e127664E069A9d23FBD7260c350d5fe4B762"
const LPTokenAddress = "0x34B6F33A5D88FcA1B8f78a510BC81673611A68F0"

//    function stake(uint256 _amount, address _recipient, bool _pool1, bool _trigger) 

export async function getEpoch(signer) {
    const contract = new ethers.Contract(poolPartyAddress, PoolParty.abi, signer);
    return await contract.epoch();
}

export async function getEpochRPC(){
  const contract = new ethers.Contract(poolPartyAddress, PoolParty.abi, new ethers.providers.JsonRpcProvider('https://eth.llamarpc.com'));
  return await contract.epoch();
}

export async function stakePool3(signer, amount, setTxnPending) {
  try {
    const lpTokenContract = new ethers.Contract(LPTokenAddress, PartyToken.abi, signer);
    const allowance = await lpTokenContract.allowance(await signer.getAddress(), pool3Address);
    if(allowance.lt(ethers.utils.parseEther(amount))) {
      const gasLimit = await lpTokenContract.estimateGas.approve(pool3Address, ethers.utils.parseEther(amount));
      const tx = await lpTokenContract.approve(pool3Address, ethers.utils.parseEther(amount), {gasLimit: gasLimit.mul(120).div(100)});
      setTxnPending(true);
      toast.info('Transaction sent!')
      await tx.wait();
      setTxnPending(false);
      toast.success('Transaction confirmed!')
    }
    const contract = new ethers.Contract(pool3Address, Pool3.abi, signer);
    const gasLimit = await contract.estimateGas.stake(ethers.utils.parseEther(amount));
    const tx = await contract.stake(ethers.utils.parseEther(amount), {gasLimit: gasLimit.mul(120).div(100)});
    setTxnPending(true);
    toast.success('Transaction sent!')
    console.log('txn is pending')
    await tx.wait();
    setTxnPending(false);
    console.log('txn is confirmed')
    toast.success('Transaction confirmed!')
  } catch (e) {
    console.log(e);
  }

}

export async function unstakePool3(signer, amount, setTxnPending) {
    const contract = new ethers.Contract(pool3Address, Pool3.abi, signer);
    const gasLimit = await contract.estimateGas.unstake(ethers.utils.parseEther(amount));

    try {
      const rewards = await contract.calculateRewardsEarned(await signer.getAddress());
      if (!rewards.isZero()) {
        const gLimit = await contract.estimateGas.claim();
        const tx = await contract.claim({gasLimit: gLimit.mul(120).div(100)});
        setTxnPending(true);
        console.log('txn is pending')
        toast.info("Transaction sent!")
        await tx.wait();
        setTxnPending(false);
        console.log('txn is confirmed')
        toast.success('Transaction confirmed!')
      }
    } catch (e) {console.log(e)}

    const tx = await contract.unstake(ethers.utils.parseEther(amount), {gasLimit: gasLimit.mul(120).div(100)});
    setTxnPending(true);
    console.log('txn is pending')
    toast.info("Transaction sent!")
    await tx.wait();
    setTxnPending(false);
    console.log('txn is confirmed')
    toast.success('Transaction confirmed!')
}

export async function claimPool3(signer, setTxnPending) {
  const contract = new ethers.Contract(pool3Address, Pool3.abi, signer);
  const gasLimit = await contract.estimateGas.claim();
  const tx = await contract.claim({gasLimit: gasLimit.mul(120).div(100)});
  setTxnPending(true);
  console.log('txn is pending')
  toast.info("Transaction sent!")
  await tx.wait();
  setTxnPending(false);
  console.log('txn is confirmed')
  toast.success('Transaction confirmed!')
}




export async function stake(signer, amount, poolId, setTxnPending) {
  try {
    const partyTokenContract = new ethers.Contract(partyTokenAddress, PartyToken.abi, signer);
    const allowance = await partyTokenContract.allowance(await signer.getAddress(), poolPartyAddress);
    console.log('allowance', Number(allowance.toString()));
    console.log('allowance', Number(allowance.toString()) === 0);
    if(allowance.lt(ethers.utils.parseEther(amount))) {
      console.log("calling approved")
      const gasLimit = await partyTokenContract.estimateGas.approve(poolPartyAddress, ethers.utils.parseEther(amount));
      const tx = await partyTokenContract.approve(poolPartyAddress, ethers.utils.parseEther(amount), {gasLimit: gasLimit.mul(120).div(100)});
      console.log("called")
      setTxnPending(true);
      toast.info('Transaction sent!')
      await tx.wait();
      setTxnPending(false);
      toast.success('Transaction confirmed!')
    }
    const contract = new ethers.Contract(poolPartyAddress, PoolParty.abi, signer);
    const gasLimit = await contract.estimateGas.stake(ethers.utils.parseEther(amount), await signer.getAddress(), poolId === 0 ? true : false);
    const tx = await contract.stake(ethers.utils.parseEther(amount), await signer.getAddress(), poolId === 0 ? true : false, {gasLimit: gasLimit.mul(120).div(100)});
    setTxnPending(true);
    toast.success('Transaction sent!')
    console.log('txn is pending')
    await tx.wait();
    setTxnPending(false);
    console.log('txn is confirmed')
    toast.success('Transaction confirmed!')
  } catch (e) {
    console.log(e);
  }

}

export async function unstake(signer, amount, poolId, setTxnPending) {
    const contract = new ethers.Contract(poolPartyAddress, PoolParty.abi, signer);
    const gasLimit = await contract.estimateGas.unstake(ethers.utils.parseEther(amount), poolId === 0 ? true : false);
    const tx = await contract.unstake(ethers.utils.parseEther(amount), poolId === 0 ? true : false, {gasLimit: gasLimit.mul(120).div(100)});
    setTxnPending(true);
    console.log('txn is pending')
    toast.info("Transaction sent!")
    await tx.wait();
    setTxnPending(false);
    console.log('txn is confirmed')
    toast.success('Transaction confirmed!')
}


export async function jump(signer, fromPoolId, setTxnPending) {
    console.log('fromtPoolId', fromPoolId);
    const poolPartyContract = new ethers.Contract(poolPartyAddress, PoolParty.abi, signer);
    const gasLimit = await poolPartyContract.estimateGas.jump(Number(fromPoolId));
    const tx = await poolPartyContract.jump(Number(fromPoolId), {gasLimit: gasLimit.mul(120).div(100)});
    setTxnPending(true);
    toast.info('Transaction sent!')
    console.log('txn is pending')
    await tx.wait();
    setTxnPending(false);
    console.log('txn is confirmed')
    toast.success('Transaction confirmed!')
}

export async function getFavorPrice(signer, amount) {
    const favorContract = new ethers.Contract(favorAddress, Favor.abi, signer);
    return Number(ethers.utils.formatEther(await favorContract.getPrice(amount)).toString()).toFixed()
}

export async function getFavorPriceRPC(amount) {
    const favorContract = new ethers.Contract(favorAddress, Favor.abi, new ethers.providers.JsonRpcProvider('https://eth.llamarpc.com'));
    return Number(ethers.utils.formatEther(await favorContract.getPrice(amount)).toString()).toFixed()
}

export async function contributeFavor(signer, amount, poolId, setTxnPending) {
    console.log(signer, amount);
    const favorContract = new ethers.Contract(favorAddress, Favor.abi, signer);
    const gasLimit = await favorContract.estimateGas.contribute(Number(poolId), Number(amount));
    const tx = await favorContract.contribute(Number(poolId), Number(amount), {gasLimit: gasLimit.mul(120).div(100)});
    setTxnPending(true);
    console.log('txn is pending')
    toast.info('Transaction sent!')
    await tx.wait();
    setTxnPending(false);
    console.log('txn is confirmed')
    toast.success('Transaction confirmed!')
}


export async function buyFavor(signer, amount, setTxnPending) {
    const partyTokenContract = new ethers.Contract(partyTokenAddress, PartyToken.abi, signer);
    const favorContract = new ethers.Contract(favorAddress, Favor.abi, signer);
    const price = await favorContract.getPrice(amount);
    const allowance = await partyTokenContract.allowance(await signer.getAddress(), favorAddress);
    console.log('allowance', Number(allowance.toString()));
    if(allowance.lt(price)) {
      const gasLimit = await partyTokenContract.estimateGas.approve(favorAddress, price);
      const tx = await partyTokenContract.approve(favorAddress, price, {gasLimit: gasLimit.mul(120).div(100)});
      setTxnPending(true);
      toast.info("Transaction sent!")
      await tx.wait()
      setTxnPending(false);
      toast.success('Transaction confirmed!')
    }
    const gasLimit = await favorContract.estimateGas.mint(Number(amount));
    const tx = await favorContract.mint(Number(amount), {gasLimit: gasLimit.mul(120).div(100)});
    setTxnPending(true);
    toast.info("Transaction sent!")
    console.log('txn is pending')
    await tx.wait();
    setTxnPending(false);
    console.log('txn is confirmed')
    toast.success('Transaction confirmed!')
}

export async function getBalances(signer) {
  const multicallProvider = new Provider(signer.provider);
  await multicallProvider.init(); // Required to initialize the provider

  const partyTokenContract = new Contract(partyTokenAddress, PartyToken.abi);
  const pool1Contract = new Contract(pool1Address, sParty.abi);
  const pool2Contract = new Contract(pool2Address, sParty.abi);
  const favorContract = new Contract(favorAddress, Favor.abi);
  const pool3Contract = new Contract(pool3Address, Pool3.abi);
  const lpTokenContract = new Contract(LPTokenAddress, PartyToken.abi);

  const address = await signer.getAddress();
  const calls = [
    partyTokenContract.balanceOf(address),
    pool1Contract.balanceOf(address),
    pool2Contract.balanceOf(address),
    favorContract.balanceOf(address),
    pool3Contract.balanceOf(address),
    lpTokenContract.balanceOf(address),
    pool3Contract.stakeTimeOf(address),
    pool3Contract.calculateRewardsEarned(address)
  ];

  const [partyToken, pool1, pool2, favor, pool3, lp, pool3Time, pool3Earnings] = await multicallProvider.all(calls);

  return {
    partyToken: (Math.floor(Number(ethers.utils.formatEther(partyToken)) * 100) / 100).toFixed(2),
    pool1: (Math.floor(Number(ethers.utils.formatEther(pool1)) * 100) / 100).toFixed(2),
    pool2: (Math.floor(Number(ethers.utils.formatEther(pool2)) * 100) / 100).toFixed(2),
    pool3: (Math.floor(Number(ethers.utils.formatEther(pool3)) * 100) / 100).toFixed(2),
    lp: (Math.floor(Number(ethers.utils.formatEther(lp)) * 100) / 100).toFixed(2),
    favor: favor.toString(),
    pool3Time: pool3Time.toString(),
    pool3TargetTime: new Date(86400000 + (pool3Time * 1000)),
    pool3Earnings: (Math.floor(Number(ethers.utils.formatEther(pool3Earnings)) * 100) / 100).toFixed(2),
  };
}

  export async function getNonSignerData(){
    const multicallProvider = new Provider(new ethers.providers.JsonRpcProvider('https://eth.llamarpc.com'));
    await multicallProvider.init(); // Required to initialize the provider
    const pool1Contract = new Contract(pool1Address, sParty.abi);
    const pool2Contract = new Contract(pool2Address, sParty.abi);
    const poolPartyContract = new Contract(poolPartyAddress, PoolParty.abi);
    const lpContract = new Contract(LPTokenAddress, PartyToken.abi)
    const partyTokenContract = new Contract(partyTokenAddress, PartyToken.abi)

    const calls = [
      pool1Contract.circulatingSupply(),
      pool2Contract.circulatingSupply(),
      poolPartyContract.sp1Favor(),
      poolPartyContract.sp2Favor(),
      lpContract.balanceOf(pool3Address),
      partyTokenContract.balanceOf(LPTokenAddress)
    ];

     const [pool1Circulating, pool2Circulating, pool1Favor, pool2Favor, pool3Circulating, partyInLP] = await multicallProvider.all(calls);
    console.log('pool3Circulating', pool3Circulating);
    const res = {
      pool1Circulating: Number(ethers.utils.formatEther(pool1Circulating)).toFixed(2),
      pool2Circulating: Number(ethers.utils.formatEther(pool2Circulating)).toFixed(2),
      pool3Circulating: Number(ethers.utils.formatEther(pool3Circulating)).toFixed(2),
      partyInLP: Number(ethers.utils.formatEther(partyInLP)).toFixed(2),
      pool3APY: ((365 * 100 * 15879120.8791209 / (Number(ethers.utils.formatEther(partyInLP)) * 2))).toFixed(2),
      pool1Favor: pool1Favor.toString(),
      pool2Favor: pool2Favor.toString(),
    };

    let favorRatio;
    let pool1Winning;
    let favorEmpty = false;

    if (pool1Favor.gt(pool2Favor)) {
      pool1Winning = true;
    } else {
      pool1Winning = false;
    }

    if (pool1Winning && pool2Favor > 0) {
      favorRatio = (pool1Favor / pool2Favor) - 1;
    } else if (!pool1Winning && pool1Favor > 0) {
      favorRatio = (pool2Favor / pool1Favor) - 1;
    } else {
      favorRatio = 25
    }

    if(pool1Favor === 0 && pool2Favor === 0) {
      favorRatio = 0;
      favorEmpty = true;
    }

    let offset = Math.atan(favorRatio) * 50 / Math.PI
    if(favorRatio > 20) {
      offset = 0.25
    }
    let pool1Odds = Number(res.pool1Circulating) / (Number(res.pool1Circulating) + Number(res.pool2Circulating));

    if (!favorEmpty) {
      if(pool1Winning && pool1Odds < 0.75) {
        pool1Odds = Math.min(pool1Odds + offset/100, 0.75);
      } else if(!pool1Winning && pool1Odds > 0.25) {
        pool1Odds = Math.max(pool1Odds - offset/100, 0.25);
      }
    }

    const pool2Odds = 1 - pool1Odds;
    const pool1Winnings = ((Number(res.pool2Circulating) / 10) + Number(res.pool1Circulating)) / (Number(res.pool1Circulating));
    const pool2Winnings = ((Number(res.pool1Circulating) / 10) + Number(res.pool2Circulating)) / (Number(res.pool2Circulating));

    return {
      pool1Odds: (pool1Odds * 100).toFixed(2),
      pool2Odds: (pool2Odds * 100).toFixed(2),
      pool1Winnings: ((pool1Winnings * 100) - 100).toFixed(2),
      pool2Winnings: ((pool2Winnings * 100) - 100).toFixed(2),
      ...res,
    };
  }
  
  export async function getPoolData(signer) {
    const multicallProvider = new Provider(signer.provider);
    await multicallProvider.init(); // Required to initialize the provider
  
    const pool1Contract = new Contract(pool1Address, sParty.abi);
    const pool2Contract = new Contract(pool2Address, sParty.abi);
    const poolPartyContract = new Contract(poolPartyAddress, PoolParty.abi);
    const lpContract = new Contract(LPTokenAddress, PartyToken.abi)
    const partyTokenContract = new Contract(partyTokenAddress, PartyToken.abi)

    const calls = [
      pool1Contract.circulatingSupply(),
      pool2Contract.circulatingSupply(),
      poolPartyContract.sp1Favor(),
      poolPartyContract.sp2Favor(),
      lpContract.balanceOf(pool3Address),
      partyTokenContract.balanceOf(LPTokenAddress)
    ];
  
    const [pool1Circulating, pool2Circulating, pool1Favor, pool2Favor, pool3Circulating, partyInLP] = await multicallProvider.all(calls);
    console.log('pool3Circulating', pool3Circulating);
    const res = {
      pool1Circulating: Number(ethers.utils.formatEther(pool1Circulating)).toFixed(2),
      pool2Circulating: Number(ethers.utils.formatEther(pool2Circulating)).toFixed(2),
      pool3Circulating: Number(ethers.utils.formatEther(pool3Circulating)).toFixed(2),
      partyInLP: Number(ethers.utils.formatEther(partyInLP)).toFixed(2),
      pool3APY: 0,
      pool1Favor: pool1Favor.toString(),
      pool2Favor: pool2Favor.toString(),
    };

    let favorRatio;
    let pool1Winning;
    let favorEmpty = false;

    if (pool1Favor.gt(pool2Favor)) {
      pool1Winning = true;
    } else {
      pool1Winning = false;
    }

    if (pool1Winning && pool2Favor > 0) {
      favorRatio = (pool1Favor / pool2Favor) - 1;
    } else if (!pool1Winning && pool1Favor > 0) {
      favorRatio = (pool2Favor / pool1Favor) - 1;
    } else {
      favorRatio = 25
    }

    if(pool1Favor === 0 && pool2Favor === 0) {
      favorRatio = 0;
      favorEmpty = true;
    }

    let offset = Math.atan(favorRatio) * 50 / Math.PI
    if(favorRatio > 20) {
      offset = 0.25
    }
    let pool1Odds = Number(res.pool1Circulating) / (Number(res.pool1Circulating) + Number(res.pool2Circulating));

    if (!favorEmpty) {
      if(pool1Winning && pool1Odds < 0.75) {
        pool1Odds = Math.min(pool1Odds + offset/100, 0.75);
      } else if(!pool1Winning && pool1Odds > 0.25) {
        pool1Odds = Math.max(pool1Odds - offset/100, 0.25);
      }
    }

    const pool2Odds = 1 - pool1Odds;
    const pool1Winnings = ((Number(res.pool2Circulating) / 10) + Number(res.pool1Circulating)) / (Number(res.pool1Circulating));
    const pool2Winnings = ((Number(res.pool1Circulating) / 10) + Number(res.pool2Circulating)) / (Number(res.pool2Circulating));

    return {
      pool1Odds: (pool1Odds * 100).toFixed(2),
      pool2Odds: (pool2Odds * 100).toFixed(2),
      pool1Winnings: ((pool1Winnings * 100) - 100).toFixed(2),
      pool2Winnings: ((pool2Winnings * 100) - 100).toFixed(2),
      ...res,
    };
  }    