import React, { useState } from 'react'
import './Card.css'
import fakeActivitiesData from '../../mocks/FakeActivities';
import { WinnerIcon, ContributeIcon, UnStakeIcon } from '../icons/Icons';

function ActivityCard() {


    return (
        <div className="Card">
            <div className='Card-title'>
                <h1 className='Card-title-text'>Activity</h1>
            </div>
            <div className='activity-container'>
                {/* Activity Card */}
                {fakeActivitiesData.map((item, index) => {
                    const bgColor = (() => {
                        switch (item.type) {
                          case "Stake":
                          case "Unstake":
                          case "Contribute":
                            return "#43447E";
                          case "Winner":
                            return "linear-gradient(180deg, #09C372 0%, #0AA589 100%)";
                          case "Loser":
                            return "linear-gradient(180deg, #C30909 0%, #A50A67 100%)";
                          default:
                            return "#43447E";
                        }
                    })();
                    let now = new Date().getTime();
                    let secondsElapsed = (now - item.time) / 1000;
                    let elapsedTime;
                    
                    if (secondsElapsed < 60) {
                        elapsedTime = `${Math.floor(secondsElapsed)} seconds ago`;
                    } else if (secondsElapsed < 3600) {
                        elapsedTime = `${Math.floor(secondsElapsed / 60)} minutes ago`;
                    } else if (secondsElapsed < 86400) {
                        elapsedTime = `${Math.floor(secondsElapsed / 3600)} hours ago`;
                    } else if (secondsElapsed < 604800) {
                        elapsedTime = `${Math.floor(secondsElapsed / 86400)} days ago`;
                    } else if (secondsElapsed < 2592000) {
                        elapsedTime = `${Math.floor(secondsElapsed / 604800)} weeks ago`;
                    } else if (secondsElapsed < 31536000) {
                        elapsedTime = `${Math.floor(secondsElapsed / 2592000)} months ago`;
                    } else {
                        elapsedTime = `${Math.floor(secondsElapsed / 31536000)} years ago`;
                    }
                    // console.log('Elapsed time', elapsedTime);

    
                    return (
                        <div className='activity-card' key={index} style={{ background: bgColor }}>
                                {/* Left */}
                                <div className='item-left'>
                                    <p className='activity-card-title-text'>
                                        {item.from.slice(0, 4)}...{item.from.slice(-4)}
                                    </p>
                                    <p className='activity-card-sub-text'>
                                        {elapsedTime}
                                    </p>
                                </div>

                                {/* Center */}
                                <div className='item-center'>
                                    {/* icon */}
                                    {item.type === "Winner" && (
                                        <WinnerIcon />
                                    )}
                                    {item.type === "Loser" && (
                                        <WinnerIcon />
                                    )}
                                    {item.type === "Contribute" && (
                                        <ContributeIcon />
                                    )}
                                    {item.type === "Unstake" && (
                                        <UnStakeIcon />
                                    )}
                                    {item.type === "Stake" && (
                                        <UnStakeIcon />
                                    )}
                                    <div className='center-card-container'>
                                        <p className='activity-card-title-text'>
                                            {item.type}
                                        </p>
                                        <p className='activity-card-sub-text'>
                                            {item.type === "Stake" || item.type === "Unstake" || item.type === "Contribute" ? (
                                                <span>
                                                    {item.pool}
                                                </span>
                                            ) : (
                                                <span>
                                                    Round {item.round}
                                                </span>
                                            )}

                                        </p>
                                    </div>
                                </div>

                                {/* Right */}
                                <div className='item-right'>
                                    <p className='activity-card-title-text'>
                                        {item.type === "Stake" || item.type === "Unstake" ? (
                                            <span>
                                                {item.amount} PARTY
                                            </span>
                                        ) : item.type === "Contribute" ? (
                                            <span>
                                                {item.amount} FAVOR
                                            </span>
                                        ) : item.type === "Winner" ? (
                                            <span>
                                                +{item.amount} PARTY
                                            </span>
                                        ) : item.type === "Loser" && (
                                            <span>
                                                -{item.amount} PARTY
                                            </span>
                                        )}

                                    </p>
                                </div>
                        </div>

                    );
                })}

            </div>
                
        </div>
    )
}

export default ActivityCard