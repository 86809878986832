import React, { useState, useEffect } from 'react';
import { useFormik, Formik, Form, Field, ErrorMessage } from "formik";
import { useAccount, useConnect } from 'wagmi';
import { stake, unstake } from '../../web3/web3';
import * as Yup from 'yup';
import './Card.css';
import { useTxnPending } from '../../contexts/TxnPendingContext';
import { usePoolsClosed } from '../../contexts/PoolsClosedContext';
import { FaPlus, FaMinus, FaLock } from 'react-icons/fa';
import CountdownTimer  from "../CountdownTimer/CountdownTimer";

function Card(props) {
  const [pool1isSmaller, setPool1isSmaller] = useState();
  const { poolColor, balances, poolData, poolId, epoch } = props;
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [unstakingFee, setUnstakingFee] = useState(0);

  useEffect(() => {
    if(poolData) {
      setPool1isSmaller(Number(poolData.pool1Circulating) < Number(poolData.pool2Circulating));
    }
  }, [poolData])

  const { connector, isConnected } = useAccount();
  const [signer, setSigner] = useState();
  const { txnPending, setTxnPending } = useTxnPending();
  const { poolsClosed, setPoolsClosed } = usePoolsClosed();

  useEffect(() => {
      async function initialize() {
          const _signer = await connector.getSigner();
          setSigner(_signer);
      }

      if(connector) {
          console.log('connector', connector);
          initialize();
      }
  }, [connector])

  const handleUnstakeInputChange = (value, setFieldValue) => {
    setFieldValue("amount", value);
  
    const fee = parseFloat(value) * 0.01; // Calculate 1% fee
    setUnstakingFee(fee);
  };
  

  // const handleMaxStake = () => {
  //   formikStake.setFieldValue('amount', balances && balances.partyToken);
  // };

  // const handleMaxUnstake = () => {
  //   formikUnstake.setFieldValue('amount', balances && balances[`pool${poolId + 1}`]);
  // };  


  const formikStake = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('Required')
        .min(0, 'Must be greater than or equal to 0')
        .max(balances && balances.partyToken, "Must be less than balance"),
    }),
    onSubmit: (values) => {
      console.log('Staking:', values.amount);
      stake(signer, values.amount, poolId, setTxnPending);
    },
  });

  const formikUnstake = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required('Required')
        .min(0, 'Must be greater than or equal to 0')
        .max(balances && balances[`pool${poolId + 1}`], 'Must be less than balance'),
    }),
    onSubmit: (values) => {
      console.log('Unstaking:', values.amount);
      unstake(signer, values.amount, poolId, setTxnPending);
    },
    onChange: (values) => {
      console.log(values)
    }
  });


  const handleMaxStake = () => {
    formikStake.setFieldValue('amount', balances && balances.partyToken);
  };

  const handleMaxUnstake = () => {
    formikUnstake.setFieldValue('amount', balances && balances[`pool${poolId + 1}`]);
  };  

  return (
    <div className="Card" style={{ '--border-color': poolColor }}>
      <div className="Card-title">
        <h1 className="Card-title-text">Pool {poolId + 1}</h1>
        <div className="Card-label">
          {pool1isSmaller ?
              poolId === 0 ? 'Small Pool' : 'Big Pool' :
              poolId === 1 ? 'Small Pool' : 'Big Pool'
          }
        </div>
      </div>
      <div>
      {pool1isSmaller ?
              poolId === 0 ? ( 
                    <p>
                      This pool has a <span style={{ color: '#FF7474' }}>higher risk</span> and a{' '}
                      <span style={{ color: '#7EFF7B' }}>higher reward</span>
                    </p>
                  ) : (
                    <p>
                      This pool has a <span style={{ color: '#7EFF7B' }}>lower risk</span> and a{' '}
                      <span style={{ color: '#FF7474' }}>lower reward</span>
                    </p>
              ) :
              poolId === 1 ? (
                <p>
                  This pool has a <span style={{ color: '#FF7474' }}>higher risk</span> and a{' '}
                  <span style={{ color: '#7EFF7B' }}>higher reward</span>
                </p>
              ) : (
                <p>
                  This pool has a <span style={{ color: '#7EFF7B' }}>lower risk</span> and a{' '}
                  <span style={{ color: '#FF7474' }}>lower reward</span>
                </p>
              )
          }
      </div>
      <div className="parent-container-card">
        <div>
          <p
            onClick={() => setSelectedGroup(0)}
            className="child"
            id={selectedGroup === 0 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Stake
          </p>
        </div>
        <div>
          <p
            onClick={() => setSelectedGroup(1)}
            className="child"
            id={selectedGroup === 1 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Unstake
          </p>
        </div>
        {/* <div>
          <p
            onClick={() => setSelectedGroup(3)}
            className="child"
            id={selectedGroup === 3 ? 'grp-btn-selected' : 'grp-btn'}
          >
            Favor
          </p>
        </div> */}
      </div>

      {selectedGroup === 0 && (
        <>
      <form onSubmit={formikStake.handleSubmit} className="input-container">
        <input
          type="text"
          name="amount"
          onChange={formikStake.handleChange}
          value={formikStake.values.amount}
          className={formikStake.errors.amount && formikStake.touched.amount ? 'input-error no-outline' : 'no-outline'}
        />
        
        {isConnected ? <>
        <div className="amount-container">
          <div>
            <p className="child available">{balances && balances.partyToken} PARTY</p>
          </div>
          <div>
            <p onClick={handleMaxStake} className="child max-button">MAX</p>
          </div>
          </div>
          </> :
          <div>
          <div>
           <p className="child available"></p>
          </div>
          <div>
            <p onClick={handleMaxStake} className="child max-button"></p>
          </div>
        </div>
        }
        {/* User Stake Info */}
        {isConnected ? <>
        <div className="user-stake-info">
          <div className="user-stake-info-container">
            <p className="user-stake-info-label">Your stake</p>
            <p className="user-stake-info-label">Win APR</p>
          </div>
          <div className="user-stake-info-container">
            <p className="user-stake-info-value">{balances && Number(balances[`pool${poolId + 1}`]).toLocaleString()} PARTY</p>
            <p className="user-stake-info-value">{poolData && (poolData[`pool${poolId + 1}Winnings`] * 365).toFixed(2)}%</p>
          </div>
        </div>
        </> :
        <div className="user-stake-info">
          <div className="user-stake-info-container">
            <p className="user-stake-info-label"></p>
            <p className="user-stake-info-label">Win APR</p>
          </div>
          <div className="user-stake-info-container">
            <p className="user-stake-info-value"></p>
            <p className="user-stake-info-value">{poolData && (poolData[`pool${poolId + 1}Winnings`] * 365).toFixed(2)}%</p>
          </div>
        </div>
      }
        {/* Stake Button */}
        {poolsClosed ?
          <button className="locked-button" disabled>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <p className="button-text">Locked</p>
                <FaLock style={{ zIndex: 1 }} />
            </div>
            
            <div className="button-text">
              {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
            </div>
          </button> :
          isConnected ? <>
          <button type="submit" className="stake-button" style={{ '--background-color': poolColor }}>
            Stake
          </button>
          </> :
           <button type="button" disabled className="stake-button" style={{ background: 'grey', cursor: 'not-allowed' }}>
            Not Connected
          </button>
        
      }

        {/* Stake Info */}
        <div className="stake-info">
          <div className="stake-info-container">
            <p className="stake-info-label">Total staked</p>
            <p className="stake-info-value">{poolData && Number(poolData[`pool${poolId + 1}Circulating`]).toLocaleString()} PARTY</p>
          </div>
          <div className="stake-info-container">
            <p className="stake-info-label">Potential gain this epoch</p>
            <p className="stake-info-value">{poolData && poolData[`pool${poolId + 1}Winnings`]}%</p>
          </div>
          <div className="stake-info-container">
            <p className="stake-info-label">Current chance of winning</p>
            <p className="stake-info-value">{poolData && poolData[`pool${poolId + 1}Odds`]}%</p>
          </div>
        </div>
      </form>
    </>
  )}
{selectedGroup === 1 && (
  <>
    <form onSubmit={formikUnstake.handleSubmit} className="input-container">
      <input
        type="text"
        name="amount"
        onChange={(e) => {
          formikUnstake.handleChange(e);
          handleUnstakeInputChange(e.target.value, formikUnstake.setFieldValue);
        }}
        value={formikUnstake.values.amount}
        className={formikUnstake.errors.amount && formikUnstake.touched.amount ? 'input-error no-outline' : 'no-outline'}
      />
      {isConnected ? <>
      <div className="amount-container">
        <div>
          <p className="child available">
            {balances && balances[`pool${poolId + 1}`]} PARTY
          </p>
        </div>
        <div>
          <p onClick={handleMaxUnstake} className="child max-button">
            MAX
          </p>
        </div>
      </div>
       </> : 
        <div className="amount-container">
        <div>
          <p className="child available">
            
          </p>
        </div>
        <div>
          <p className="child max-button">
            
          </p>
        </div>
      </div>
     }
      {/* User Stake Info */}
      {isConnected ? <>
      <div className="user-stake-info">
        <div className="user-stake-info-container">
          <p className='user-stake-info-label'>Your stake</p>
          <p className="user-stake-info-label">Unstaking fee (1%)</p>
        </div>
        <div className="user-stake-info-container">
          <p className="user-stake-info-value">{balances && Number(balances[`pool${poolId + 1}`]).toLocaleString()} PARTY</p>
          <p className="user-stake-info-value">
            {unstakingFee ? Number(Number(unstakingFee).toFixed(2)).toLocaleString() : 0} PARTY
          </p>
        </div>
      </div>
      </> :
      <div className="user-stake-info">
        <div className="user-stake-info-container">
          <p className='user-stake-info-label'></p>
          <p className="user-stake-info-label">Unstaking fee (1%)</p>
        </div>
        <div className="user-stake-info-container">
          <p className="user-stake-info-value"></p>
          <p className="user-stake-info-value">
            {unstakingFee ? Number(Number(unstakingFee).toFixed(2)).toLocaleString() : 0} PARTY
          </p>
        </div>
      </div>
    }

      {/* Unstake button */}
      {poolsClosed ?
        <button className="locked-button" disabled>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <p className="button-text">Locked</p>
              <FaLock style={{ zIndex: 1 }} />
          </div>
          .
          <div className="button-text">
            {epoch && <CountdownTimer targetTimestamp={new Date(epoch.endTime * 1000)} />}
          </div>
        </button> :
        isConnected ? <>
        <button
          type="submit"
          className="unstake-button"
          style={{ '--background-color': poolColor }}
        >
          Unstake
        </button>
        </> :
        <button
          type="button"
          className="unstake-button"
          style={{ background: 'grey', cursor: 'not-allowed' }}
        >
          Not Connected
        </button>
      }

      <div className="stake-info">
        <div className="stake-info-container">
          <p className="stake-info-label">Total staked</p>
          <p className="stake-info-value">{poolData && Number(poolData[`pool${poolId + 1}Circulating`]).toLocaleString()} PARTY</p>
        </div>
        <div className="stake-info-container">
          <p className="stake-info-label">Potential gain this epoch</p>
          <p className="stake-info-value">{poolData && poolData[`pool${poolId + 1}Winnings`]}%</p>
        </div>
        <div className="stake-info-container">
          <p className="stake-info-label">Current chance of winning</p>
          <p className="stake-info-value">{poolData && poolData[`pool${poolId + 1}Odds`]}%</p>
        </div>
      </div>
    </form>
  </>
)}

</div>
);
}

export default Card;
         
