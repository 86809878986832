const types = ["Stake", "Unstake", "Contribute", "Winner", "Loser"];
const pools = ["pool 1", "pool 2"];
const addresses = [
  "0x1234567890abcdefABCDEF1234567890abcdef01",
  "0x1234567890abcdefABCDEF1234567890abcdef02",
  "0x1234567890abcdefABCDEF1234567890abcdef03",
];

function randomFromArray(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

const fakeActivitiesData = Array.from({ length: 50 }, () => {
  const type = randomFromArray(types);
  const pool = randomFromArray(pools);
  const from = randomFromArray(addresses);
  const amount = Math.floor(Math.random() * 1000) + 100;
  const round = Math.floor(Math.random() * 20) + 1;
  const time = randomDate(new Date(2022, 3, 1), new Date()).getTime();
  

  const activity = {
    type,
    amount,
    pool,
    time,
    from,
  };

  if (type === "Winner" || type === "Loser") {
    activity.round = round;
  }

  return activity;
});

export default fakeActivitiesData;
