import React, { useState, useEffect } from "react";
import "./WinningPoolProgress.css";

function WinningPoolProgress({ poolData }) {
    const strokeWidth = 28;
    const progressRadius = 160 - strokeWidth / 2;
    const progressCircumference = 2 * Math.PI * progressRadius;
    const [currentPercentage, setCurrentPercentage] = useState(0);
    const [favorRatioPercentage, setfavorRatioPercentage] = useState(0);
    const targetPercentage = poolData
        ? poolData.pool1Odds > poolData.pool2Odds
            ? 100 - poolData.pool1Odds
            : 100 - poolData.pool2Odds
        : 0;
    const progressLength = ((currentPercentage / 100) / 2) * progressCircumference;

    useEffect(() => {
        let timeout;
        if(poolData) {
            setCurrentPercentage(
                100 * Number(poolData.pool1Favor / (Number(poolData.pool1Favor) + Number(poolData.pool2Favor)))
            )    
            if(Number(poolData.pool2Favor) > Number(poolData.pool1Favor)) {
                setfavorRatioPercentage(100 * ((Number(poolData.pool2Favor) / Number(poolData.pool1Favor)) - 1))
            } else if (Number(poolData.pool1Favor) > Number(poolData.pool2Favor)) {
                setfavorRatioPercentage(100 * ((Number(poolData.pool1Favor) / Number(poolData.pool2Favor)) - 1))
            } else {
                setfavorRatioPercentage(0)
            }

        }
        // if (currentPercentage < targetPercentage) {
        //     timeout = setTimeout(() => {
        //         setCurrentPercentage(currentPercentage + 1);
        //     }, 10);
        // } else if (currentPercentage > targetPercentage) {
        //     setCurrentPercentage(targetPercentage);
        // }
        return () => clearTimeout(timeout);
    }, [currentPercentage, targetPercentage]);


    return (
        <div className="progress-container">
            <svg className="progress-ring" viewBox="0 0 320 160">
                {/* Half circle */}
                <circle
                    className="progress-ring__circle"
                    stroke="#397BDE"
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    r={progressRadius}
                    cx="160"
                    cy="160"
                />
                {/* Progress circle */}
                {poolData ? <circle
                    className="progress-ring__circle"
                    stroke="#E45CF5"
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    r={progressRadius}
                    cx="160"
                    cy="160"
                    strokeDasharray={progressCircumference}
                    strokeDashoffset={progressCircumference - progressLength}
                    transform="rotate(-180 160 160)"
                />
                    : <circle
                        className="progress-ring__circle"
                        stroke="#f5f5f5"
                        strokeWidth={strokeWidth}
                        fill="transparent"
                        r={progressRadius}
                        cx="160"
                        cy="160"
                    />}
                <text
                    className="progress-text__percentage"
                    x="50%"
                    y="65%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                >
                    {favorRatioPercentage.toFixed(2)}%
                </text>

                <text
                    className="progress-text__label"
                    x="50%"
                    y="90%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                >
                    {console.log('currentPercentage', currentPercentage)}
                    {currentPercentage === 50 ? "The Pools are Tied!" : currentPercentage < 50 ? "Pool 2 is winning" : "Pool 1 is winning"}
                </text>
            </svg>
            <div className="progress-text__container">
                <p className="progress-text__label">Pool 1</p>
                <p className="progress-text__label">Pool 2</p>
            </div>
        </div>
    );
}
export default WinningPoolProgress;
