import logo from './assets/images/logo.png';
import './App.css';
import Card from './components/Card/Card';
import FavorCard from './components/Card/FavorCard';
import ActivityCard from './components/Card/activityCard';
import { useWeb3Modal } from "@web3modal/react";
import { goerli, useAccount, useSigner, useConnect, useDisconnect, mainnet } from 'wagmi'
import Menu from './components/hamburger/Menu';
import StakesCard from './components/Card/StakesCard';
import EpochProgress from './components/progress/EpochProgress';
import BuyCard from './components/Card/BuyCard';
import CardC from './components/Card/CardC';
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { CiMenuFries } from 'react-icons/ci';
import { TwitterFooterIcon, TelegramFooterIcon, EtherscanFooterIcon } from './components/icons/Icons';
import { getBalances, getPoolData, getEpoch, getNonSignerData, getEpochRPC } from './web3/web3';
import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { usePoolsClosed } from './contexts/PoolsClosedContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const fakeEpochData = {
  epochs: [
    {
      name: "Unlocked",
      startProgress: 0,
      endProgress: 33.333,
      timeLeft: "02:15:42",
      isActive: function (progress) {
        return progress >= this.startProgress && progress <= this.endProgress;
      },
    },
    {
      name: "Locked",
      startProgress: 33.334,
      endProgress: 100,
      timeLeft: "04:37:29",
      isActive: function (progress) {
        return progress >= this.startProgress && progress <= this.endProgress;
      },
    },
    // {
    //   name: "Epoch Three",
    //   startProgress: 66.667,
    //   endProgress: 100,
    //   timeLeft: "06:01:15",
    //   isActive: function (progress) {
    //     return progress >= this.startProgress && progress <= this.endProgress;
    //   },
    // },
  ],

};



function App() {
  const { connector, address, isConnected } = useAccount();
  // const { data: signer } = useSigner({chainId: 31337})
  const { isOpen, open, close, setDefaultChain } = useWeb3Modal();
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const { disconnect } = useDisconnect();
  const  [balances, setBalances] = useState();
  const  [poolData, setPoolData] = useState();
  const  [epoch, setEpoch] = useState();
  const [epochProgress, setEpochProgress] = useState(3);
  const { poolsClosed, setPoolsClosed } = usePoolsClosed();

  const handleConnect = async() => {
    // set default chain to goerli for testing
    await setDefaultChain(mainnet)
    open()
  };

  const handleOpen = async() => {
    open();
  }

  useEffect(() => {


    async function initialize() {
      const signer = await connector.getSigner();
      setEpoch(await getEpoch(signer));
      setBalances(await getBalances(signer));
      setPoolData(await getPoolData(signer));
    }

    async function notConnectInit(){
      setPoolData(await getNonSignerData());
      setEpoch(await getEpochRPC());
    }

    // console.log('signer: ', signer)
    if(connector) {
      console.log('connector', connector);
      initialize();
    }else{
      notConnectInit();
    }
  }, [connector, setBalances])

  useEffect(() => {
    if(epoch) {
      const endTime = Number(epoch.endTime.toString());
      const length = Number(epoch._length.toString());
      const startTime = endTime - length;
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const progress = currentTime - startTime;
      const progressPct = Math.round(100 * (progress / length));
      if(progressPct > 3) {
        setEpochProgress(Math.min(progressPct, 100));
      }

      if(progressPct >= 33) {
        setPoolsClosed(true);
      }
    }
  }, [epoch])

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 950) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // Set the initial value based on the current window size
    handleResize();

    // Add the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div className="App">
      {isMobile ? (
        <header className="App-header">
        <div className="mobileDiv">
          <div style={{cursor: 'pointer'}} onClick={() => window.open('https://poolpartyyy.com')} className='App-logo-div'>
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          {!isConnected ?
            <button className="connect-button" onClick={handleConnect}>
              Connect Wallet
            </button>
             :
            <button className="connect-button" onClick={handleConnect}>
              {address && address.slice(0, 6) + "..." + address.slice(-4)}
            </button>
          }
            </div>
        </header>
      ) : (
      <header className="App-header">
        <div style={{cursor: 'pointer'}} onClick={() => window.open('https://poolpartyyy.com')} className='App-logo-div'>
          <img src={logo} className="App-logo" alt="logo" />
        </div>
        {isConnected ? <>
        <div className='parent-container-header'>
          <div>
            <p className="child" id="party-balance">{balances && Number(Number(balances.partyToken).toFixed(2)).toLocaleString()} PARTY</p>
          </div>
          <div>
            <p className="child" id="favor-balance">{balances && Number(Number(balances.favor).toFixed(2)).toLocaleString()} FAVOR</p>
          </div>
          <div className="child">
            {/* Custom Button */}
            <button className="connected-button" onClick={handleOpen}>
              {address && address.slice(0, 6) + "..." + address.slice(-4)}
            </button>
          </div>
        </div>
        </> :
        <div>
            {/* Custom Wallet Button */}
            <button className="connect-button" onClick={handleConnect}>
              Connect Wallet
            </button> 
        </div>
        }

      </header>
      )}
      <body>
        <div className="stakes-parent-container">
          <EpochProgress epoch={epoch} progress={epochProgress} epochs={fakeEpochData} />
        </div>
        {balances && (balances.pool1 > 0 || balances.pool2 > 0 || balances.pool3 > 0) &&
        <div className="stakes-parent-container stakes-card">
          <StakesCard poolData={poolData} balances={balances} className="child" poolColor="#FFEBA3" />
        </div>

        }
        <div className="stakes-parent-container">
          <div className="parent-grid-container">
            <Card className="child" poolId={0} balances={balances} poolData={poolData} epoch={epoch} poolColor="#69B3F7" />
            <Card className="child" poolId={1} balances={balances} poolData={poolData} epoch={epoch} poolrColor="#FFEBA3" />
            <CardC className="child" poolData={poolData} balances={balances} poolId={2} poolColor="#0AB67D" />
            <BuyCard balances={balances} className="child" poolColor="#CA69F7" />
            <FavorCard poolData={poolData} className="child" poolColor="#393A77" />
            {/* <ActivityCard className="child" /> */}
          </div>
        </div>
        <ToastContainer
        position="bottom-right"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        // rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />

      </body>
      <footer className="footer">
        <div className="footer-left">
          {/* {isMuted ? (
            <FaVolumeMute className="footer-sound-icon" onClick={() => setIsMuted(false)} />
          ) : (
            <FaVolumeUp className="footer-sound-icon" onClick={() => setIsMuted(true)} />
          )} */}
          <button onClick={() => window.open("https://app.uniswap.org/#/swap?outputCurrency=0x0fe0ed7f146cb12e4b9759aff4fa8d34571802ca", 'blank')} className="footer-button">Buy $PARTY</button>
          <button onClick={() => window.open("https://app.uniswap.org/#/add/v2/ETH/0x0fE0Ed7F146Cb12e4B9759afF4FA8d34571802ca", 'blank')} className="footer-button">Add Liquidity</button>
        </div>
        <div className="footer-right">
          <div onClick={() => window.open("https://twitter.com/Pool_Partyyy", 'blank')}><TwitterFooterIcon className='footer-icon' /></div>
          <div onClick={() => window.open("https://t.me/ppoolparty", 'blank')}><TelegramFooterIcon className='footer-icon' /></div>
          <div onClick={() => window.open("https://etherscan.io/address/0x0fe0ed7f146cb12e4b9759aff4fa8d34571802ca", 'blank')}><EtherscanFooterIcon className='footer-icon' /></div>
        </div>
      </footer>
    </div>
  );
}

export default App;
